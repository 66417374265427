import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
// import axios from 'axios';

import BreadcrumbType from '../Common/Breadcrumb';

const Contact = () => {
    const [reason, setReason] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [company, setCompany] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [comments, setComments] = useState('');

    const sendEmail = (e) => {
        e.preventDefault();

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Access-Control-Allow-Origin", "*");
        myHeaders.append("Access-Control-Allow-Methods", "GET,PUT,POST,DELETE,OPTIONS");

        var raw = JSON.stringify({
            firstName,
            lastName,
            company,
            email,
            phone,
            reason,
            comments
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("https://api.7ep-dev.7-eleven.com/fuels/wholesale/fw-send-email-svc/api/fw-svc/send-email", requestOptions)
            .then(response => {
                console.log('response.json(): ', response.json())
                return response.json()
            })
            .then(result => console.log('result: ', result))
            .catch(error => console.log('error', error));

        // axios.post('https://api.7ep-dev.7-eleven.com/fuels/wholesale/fw-send-email-svc/api/fw-svc/send-email', {
        //     headers: {
        //         'Content-Type': 'application/json',
        //         "Access-Control-Allow-Origin": "*"
        //     }
        // }, {
        //     firstName,
        //     lastName,
        //     company,
        //     email,
        //     phone,
        //     reason,
        //     comments
        // })
        //     .then(function (response) {
        //         console.log('response: ', response);
        //     })
        //     .catch(function (error) {
        //         console.log('error: ', error);
        //     });
    };

    const onReasonChange = (e) => {
        e.preventDefault();
        if (e.target.value) {
            setReason(e.target.value);
        } else {
            setReason('');
        }
    }

    return (
        <>
            <BreadcrumbType breadcrumbArray={['Home', 'Contact Us']} />
            <div id="content" className="col-md-offset-1 col-md-7 col-sm-12">
                <h1 className="text-green">Contact us today!</h1>
                <Form onSubmit={(e) => sendEmail(e)}>
                    <div className='form-group'>
                        <Form.Select
                            className="form-control hasCustomSelect"
                            bsPrefix='form-select'
                            aria-label="Reason for Contact"
                            name="reason"
                            value={reason}
                            onChange={(e) => onReasonChange(e)}>
                            {['Reason for Contact', 'General Inquiry', 'Existing Account', 'Critical Issue', 'Login Issue'].map((item, index) => {
                                if (!index) return <option key={index}>{item}</option>;
                                return <option value={item} key={index}>{item}</option>
                            })}
                        </Form.Select>
                    </div>
                    <br />
                    <Form.Control size="lg" type="text" placeholder="First Name" name="firstName" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                    <br />
                    <Form.Control size="lg" type="text" placeholder="Last Name" name="lastName" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                    <br />
                    <Form.Control size="lg" type="text" placeholder="Company" name="company" value={company} onChange={(e) => setCompany(e.target.value)} />
                    <br />
                    <Form.Control size="lg" type="email" placeholder="Email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                    <br />
                    <Form.Control size="lg" type="text" placeholder="Phone" name="phone" value={phone} onChange={(e) => setPhone(e.currentTarget.value)} />
                    <br />
                    <Form.Control as="textarea" rows={3} placeholder="Question or Comments" name="comments" value={comments} onChange={(e) => setComments(e.target.value)} />
                    <br></br>
                    <div className='form-group'>
                        <div className='col-sm-10 col-md-9 col-lg-10'></div>
                        <div className='col-sm-2 col-md-3 col-lg-2'>
                            <button type='submit' onClick={(e) => sendEmail(e)} className='btn btn-orange pull-right'>Submit</button>
                        </div>
                    </div>
                </Form>
            </div>
            <div id="sidebar" className="col-sm-12 col-md-3"><p></p><div className="testimonial-block">
                <div className="quote">
                    <p>We are very happy to do business with SEI Fuels. They have good service, easy ordering, and on-time deliveries. The drivers they use are good, and our Dealer Business Consultant is very helpful.</p>
                </div>
                <span className="divider"></span>
                <p className="attribute"><strong>Yager Food Mart</strong><br />
                    <em>Austin, TX</em></p>
            </div>
            </div>
        </>
    )
}

export default Contact;
