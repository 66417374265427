import React from 'react'
import { Container } from 'react-bootstrap';

const Footer = () => {
    return (
        <footer id="site_footer" className="row">
            <Container>
                <div className="col-xs-12">
                    <ul className="nav-footer hidden-xs"><li className=""><a href="/fuel-distribution">Services</a></li>
                        <li className=""><a href="/our-fuel-brands">Fuel Brands</a></li>
                        <li className=""><a href="/career-opportunities">Careers</a></li>
                        <li className=""><a href="/company-information">Company Info</a></li>
                        <li className=""><a href="/contact-us">Contact Us</a></li>
                        <li className=""><a href="/privacy-policy">Privacy Policy</a></li>
                        <li className=""><a href="/terms-of-use">Terms of Use</a></li>
                    </ul>
                    <p className="legal">Copyright © 2024 7-Eleven, Inc. All Rights Reserved  </p>
                    <p className="legal">Phone: 855-711-0711</p>
                </div>
            </Container>
        </footer>
    )
}

export default Footer;
