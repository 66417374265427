import React from 'react';
import { Image } from 'react-bootstrap';
import FuelPageCareers from '../images/Page_Banners_careers_3_6136.png';
import BreadcrumbType from '../Common/Breadcrumb';

const Careers = () => {
    return (
        <>
            <div id='billboard'>
                <Image src={FuelPageCareers} />
            </div>
            <BreadcrumbType breadcrumbArray={['Home', 'Careers']} />
            <div id="content" className="col-md-offset-1 col-md-7 col-sm-12">
                <h1 className="text-green">a great place to work</h1><p>SEI Fuels is a wholly owned subsidiary of 7‑Eleven, based in Irving, Texas. Working for SEI Fuels comes with all of the advantages of working for 7‑Eleven – a great company with a long history of supporting and nurturing employees at all levels.</p>
                <p>7‑Eleven didn’t just develop convenience retailing … we invented it. Just like our stores, our employees are always on the move – learning, growing and advancing in their careers. Each person in the vast 7‑Eleven family of professionals knows he or she really counts, is genuinely respected, and will be given every opportunity to succeed and advance. Currently, 7‑Eleven is home to more than 20,000 corporate employees who recognize, as we do, that a combination of effective teamwork, great friendships, and selfless service will enhance our company’s position as the leader of the convenience industry.</p>
                <h2>lEADERSHIP PRINCIPLES</h2>
                <ol>
                    <li>Be Customer Obsessed</li>
                    <li>Be Courageous With Your Point of View</li>
                    <li>Challenge the Status Quo</li>
                    <li>Act Like an Entrepreneur</li>
                    <li>It Can Be Done</li>
                    <li>Do The Right Thing</li>
                    <li>Be Accountable</li>
                </ol>
                <p><strong>What is Servant Leadership?</strong></p>
                <p>We believe that to be effective leaders at 7‑Eleven, we must think about the needs of our customers and our teams before we tend to our own needs.</p>
                <p>We call this Servant Leadership, and it's the foundation of everything we do here. As Servant Leaders, we are guided by seven core values that we live by every day.</p>
                <p>These values help us serve our customers, our teams and our communities. They are the heart of 7‑Eleven and what makes us who we are.</p>
                <h2>let's get to know each other</h2>
                <p>To learn more about career opportunities at SEI Fuels, <a href="http://careers.7-eleven.com/careers/Careers.html" target="_blank" rel="noopener noreferrer">visit the 7‑Eleven careers site</a>.</p>
            </div>
            <div id="sidebar" className="col-sm-12 col-md-3"><p></p>
                <div className="testimonial-block">
                    <div className="quote">
                        <p>Why is it good to do business with SEI Fuels? The most simple and dramatic answer is dependability. As a gas operator and retail store owner, consistency of product is our life blood. I can count on SEI Fuels to deliver the product on time, to provide a quality product, and to be available 24/7. That is what I demand, and they deliver. Dependability is not a given; it is earned. Good job SEI Fuels.</p>
                    </div>
                    <span className="divider"></span>
                    <p className="attribute"><strong>Warren Lynn Haddix</strong><br />
                        <em>Austin, TX</em></p>
                </div>
            </div>
        </>
    )
}

export default Careers;
