import React from 'react';
import { Image } from 'react-bootstrap';
import FuelValuedAddedBanner from '../../images/SEI_Fuels_value_added_services_3_1458.png';
import BreadcrumbType from '../../Common/Breadcrumb';

const ValueAddedService = () => {
    return (
        <>
            <div id='billboard'>
                <Image src={FuelValuedAddedBanner} />
            </div>
            <BreadcrumbType breadcrumbArray={['Home', 'Services', 'Value-Added Services']} />
            <div id="content" className="col-md-offset-1 col-md-7 col-sm-12">
                <h1 className="text-green">customer service unrivaled in the fuel industry</h1><p>We are truly customer obsessed.&nbsp; We can manage your fuel, so you can focus on making your store more profitable. Here are some examples of the services we can provide.</p>
                <h2>Dealer Training</h2>
                <p>SEI Fuels is serious about helping you be as successful as possible. &nbsp;We offer periodic training about brand requirements. We have relationships with most national fuel brands, so we can help you maintain brand standards and brand-recommended customer service guidelines. Our most successful dealers get <a title="Customer Recognition" href="/customer-recognition">recognized each year</a>&nbsp;with luxury trips to exotic locations.</p>
                <h2>Vendor buying programs</h2>
                <p>We also have established relationships with fuel equipment suppliers. Because of our size, we are able to negotiate competitive pricing for the dispenser and P.O.S. systems you need.&nbsp; Our equipment pricing advantages can be combined with attractive financing options, to provide you with industry leading programs. &nbsp;</p>
                <h2>Image AND EQUIPMENT Enhancement Programs</h2>
                <p>The national brands have image programs, where they fund upgrades to qualifying branded stores. We are familiar with these programs and can help you get your share of the available funding. SEI Fuels also contributes to our dealer image enhancement on a site by site basis.</p>
                <h2><strong>EQUIPMENT FINANCING</strong></h2>
                <p>In addition, SEI also works with vendors, to provide unique financing programs to assist with site upgrade investments for new equipmnet enhancements.&nbsp; Our dealers are able to obtain financing for pump, tanks, and other equipment upgrades as part of our national financing program with Patriot Capital.&nbsp;<a href="https://patriotcapitalcorp.com/contact-us/application">Apply Now</a></p>
                <h2>Methods of Operation</h2>
                <p>You can choose how you want to handle fuel sales. We can sell you fuel, or you can choose to sell fuel on a commission basis, where we control the fuel, and we pay a commission to you for handling it for us. Other dealers prefer to lease the entire operation, and we earn a slight mark-up on fuel. Let us show you how flexible our programs can be.</p>
                <h2>Credit Terms</h2>
                <p>We offer varying credit options. If you have past credit problems, we will even work with you on a pre-pay basis.&nbsp; Our credit manager will work with you.</p>
                <h2>Credit Card Processing</h2>
                <p>For branded fuels, all credit card processing will go through the brand.</p>
                <p>For unbranded fuel, we can set up a program to handle credit card processing with very competitive rates. You can deal directly with the credit card processor, or we can apply your credit card payments against what you owe us. We can deposit credit card payments directly into your account so you have the cash flexibility.</p>
                <h2>Fuel Ordering</h2>
                <p>Order your own fuel, or we can order fuel for you.&nbsp; If you prefer, we can automate the ordering process. Our inventory optimization service digitally reads the inventory levels in your tanks, and when you need fuel, it gets delivered automatically.</p>
            </div>
            <div id="sidebar" className="col-sm-12 col-md-3"><p></p>
                <div className="testimonial-block">
                    <div className="quote">
                        <p>We are very happy to do business with SEI Fuels. They have good service, easy ordering, and on-time deliveries. The drivers they use are good, and our Dealer Business Consultant is very helpful.</p>
                    </div>
                    <span className="divider"></span>
                    <p className="attribute"><strong>Yager Food Mart</strong><br />
                        <em>Austin, TX</em></p>
                </div>
                <p></p>
            </div>
        </>
    )
}

export default ValueAddedService;
