import React, { useState } from 'react';
import BreadcrumbType from '../Common/Breadcrumb';
import {
    ComposableMap,
    Geographies,
    Geography,
    Marker,
    Annotation
} from 'react-simple-maps';
import allStates from "../Common/AllStates.json";
import geoLocationOfStates from "../Common/Map.json";
import { geoCentroid } from "d3-geo";
import consultantData from "../data-store/ConsultantDetails.json";
import Form from 'react-bootstrap/Form';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const FindConsultant = () => {
    const [showDetails, setShowDetails] = useState(false);
    const [consultantDetail, setConsultantDetails] = useState({});
    let consultantDetails = {};

    const geoUrl = geoLocationOfStates;

    const handleClick = geo => () => {
        const consultant = consultantData;
        const cur = allStates.find((s) => s.val === geo.id);
        consultantDetails = consultant[cur.id];
        if (consultantDetails !== undefined && consultantDetails !== null) {
            setConsultantDetails((consultantDetail) => consultantDetails);
            setShowDetails((showDetails) => true);
        } else {
            setShowDetails((showDetails) => false);
        }
    };

    const offsets = {
        VT: [50, -8],
        NH: [34, 2],
        MA: [30, -1],
        RI: [28, 2],
        CT: [35, 10],
        NJ: [34, 1],
        DE: [33, 0],
        MD: [47, 10],
        DC: [49, 21]
    };

    return (
        <>
            <BreadcrumbType breadcrumbArray={['Home', 'Find a Consultant']} />
            <br /><br />
            <div id="content" className="col-md-offset-1 col-md-7 col-sm-12">
                <h1 className="text-green">find the business consultant in your area</h1>
                <div className='visible-xs-block'>
                    <p>Select your state to learn more about the consultants in your area.</p>
                    <div className='form-group'>
                        <Form.Select className="form-control hasCustomSelect" bsPrefix='form-select' aria-label="Reason for Contact">
                            <option>Reason for Contact</option>
                            <option value="1">General Inquiry</option>
                            <option value="2">Existing Account</option>
                            <option value="3">Critical Issue</option>
                            <option value="3">Login Issue</option>
                        </Form.Select>
                    </div>
                </div>
                <div className="hidden-xs">
                    <ComposableMap projection="geoAlbersUsa">
                        <Geographies geography={geoUrl}>
                            {({ geographies }) => (
                                <>
                                    {geographies.map((geo) => (
                                        <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">{geo.properties.name}</Tooltip>}>
                                            <Geography
                                                key={geo.rsmKey}
                                                stroke="#FFF"
                                                geography={geo}
                                                fill="#DDD"
                                                onClick={handleClick(geo)} />
                                        </OverlayTrigger>
                                    ))}
                                    {geographies.map((geo) => {
                                        const centroid = geoCentroid(geo);
                                        const cur = allStates.find((s) => s.val === geo.id);
                                        return (
                                            <g key={geo.rsmKey + "-name"}>
                                                {cur &&
                                                    centroid[0] > -160 &&
                                                    centroid[0] < -67 &&
                                                    (Object.keys(offsets).indexOf(cur.id) === -1 ? (
                                                        <Marker coordinates={centroid}>
                                                            <text y="2" fontSize={14} textAnchor="middle" onClick={handleClick(geo)}>
                                                                {cur.id}
                                                            </text>
                                                        </Marker>

                                                    ) : (
                                                        <Annotation
                                                            subject={centroid}
                                                            dx={offsets[cur.id][0]}
                                                            dy={offsets[cur.id][1]}
                                                        >
                                                            <text x={4} fontSize={14} alignmentBaseline="middle" onClick={handleClick(geo)}>
                                                                {cur.id}
                                                            </text>
                                                        </Annotation>
                                                    ))}
                                            </g>
                                        );
                                    })}
                                </>
                            )}
                        </Geographies>
                    </ComposableMap>
                </div>
                {showDetails && (
                    <div id="text">
                        <h2>{consultantDetail.name}</h2>
                        <div className="consultants-listing row">
                            <div className="item col-md-6">
                                <h3>{consultantDetail.consultant} {consultantDetail.position}</h3>
                                <p>
                                    Email <a href="/contact-us?i=73&amp;state=Wyoming">{consultantDetail.consultant}</a><br />
                                    <span className="hidden-xs">{consultantDetail.contact}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                )
                }
            </div>
            <div id="sidebar" className="col-sm-12 col-md-3"><p></p><div className="testimonial-block">
                <div className="quote">
                    <p>Why is it good to do business with SEI Fuels? The most simple and dramatic answer is dependability. As a gas operator and retail store owner, consistency of product is our life blood. I can count on SEI Fuels to deliver the product on time, to provide a quality product, and to be available 24/7. That is what I demand, and they deliver. Dependability is not a given; it is earned. Good job SEI Fuels.</p>
                </div>
                <span className="divider"></span>
                <p className="attribute"><strong>Warren Lynn Haddix</strong><br />
                    <em>Austin, TX</em></p>
            </div>
            </div>
        </>
    )
}

export default FindConsultant;
