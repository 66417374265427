import React from 'react';
import { Image } from 'react-bootstrap';
import testimonials from '../../data-store/Testimonial.json';
import TestimonialBanner from '../../images/Page_Banners_testimonials_3_3085.png';
import BreadcrumbType from '../../Common/Breadcrumb';

const Testimonial = () => {
    return (
        <>
            <div id='billboard'>
                <Image src={TestimonialBanner} />
            </div>
            <BreadcrumbType breadcrumbArray={['Home', 'Company Info', 'Testimonials']} />
            <div id="content" className="col-md-offset-1 col-md-10 col-sm-12">
                <h1 className="text-green">hear what our customers are saying</h1>
                <div className="testimonials-listing">
                    {testimonials.map((data =>
                        <div className="item">
                            <h2>{data.name}<small><em> - {data.location}</em></small></h2>
                            <p>{data.comment}</p>
                        </div>
                    ))}
                </div>

            </div>
        </>
    )
}

export default Testimonial;
