import React from 'react';
import { Image } from 'react-bootstrap';
import FuelDistributionBanner from '../../images/Page_Banners_fuel_distribution_3_4962.png';
import BreadcrumbType from '../../Common/Breadcrumb';

const FuelDistribution = () => {
    return (
        <>
            <div id='billboard'>
                <Image src={FuelDistributionBanner} />
            </div>
            <BreadcrumbType breadcrumbArray={['Home', 'Services', 'Fuel Distribution']} />
            <div id="content" className="col-md-offset-1 col-md-7 col-sm-12">
                <h1 className="text-green">fuel distribution services that fit your needs</h1><p>SEI Fuels provides fuel distribution services for retail gas stations, commercial businesses, fleet fueling, and to bulk plants.</p>
                <p>We know you are busy, and the demands of your business pull you in many directions. Our dealer business consultants are always available to help you grow your business.</p>
                <ul>
                    <li>Our typical delivery turnaround is within 24 hours of your order.&nbsp; In some cases, even more quickly.</li>
                    <li>Our inventory optimization service digitally reads the inventory levels in your tanks, and when you need fuel, it gets delivered automatically.</li>
                </ul>
                <p>We make the fuel distribution process as simple as possible, so you can focus on running your business.&nbsp;<a href="/find-a-consultant">Talk with one of our business consultants today</a> – see how our decades of wholesale fuels experience can improve your business.</p>
            </div>
            <div id="sidebar" className="col-sm-12 col-md-3">
                <div>
                    <div className="testimonial-block">
                        <div className="quote">
                            <p>I feel extremely privileged to be working with a company that is represented by such caring team members. SEI treats me like family, and it’s always comforting knowing you are running your business with a company you can trust! Thank you, SEI, I look forward to continued success with you all!</p>
                        </div>
                        <span className="divider"></span>
                        <p className="attribute"><strong>Frank Rabadi</strong><br />
                            <em>Dallas, TX</em>
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FuelDistribution;
