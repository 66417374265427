import React from 'react';
import { Image } from 'react-bootstrap';
import FuelAboutUsBanner from '../../images/Page_Banners_about_us_3_2619.png';
import BreadcrumbType from '../../Common/Breadcrumb';

const AboutUs = () => {
    return (
        <>
            <div id='billboard'>
                <Image src={FuelAboutUsBanner} />
            </div>
            <BreadcrumbType breadcrumbArray={['Home', 'Company Info', 'About Us']} />
            <div id="content" className="col-md-offset-1 col-md-10 col-sm-12">
                <h1 className="text-green">a long history –&nbsp;a promising future</h1><p>SEI Fuels is a merging of two great histories. In 2012, TETCO retail and wholesale assets were acquired by 7‑Eleven, Inc., and SEI Fuels Wholesale Division was born. TETCO was a 60-year-old multi-state operation, and we continue to grow with more dealers in more states. 7‑Eleven was founded in 1927 and has grown to one of the largest corporations in the country.</p>
                <p>SEI Fuels is a seasoned and experienced organization. Our leadership and many members of our sales team have built their careers in the petroleum industry. We enjoy the challenging and competitive nature of the industry. We understand your business, and we want to share our experience with you.</p>
                <p>Both 7‑Eleven and SEI Fuels are customer obsessed.&nbsp; When we make a commitment with you, we will put that commitment ahead of all other priorities. Our relationship with you has one goal – to help you succeed – and we take pride in all of our customers.</p>
            </div>
        </>
    )
}

export default AboutUs;
