import React from 'react';
import { Image } from 'react-bootstrap';
import SiteSelectionBanner from '../../images/Page_Banners_site_selection_3_8475.png';
import BreadcrumbType from '../../Common/Breadcrumb';

const SiteSelection = () => {
    return (
        <>
            <div id='billboard'>
                <Image src={SiteSelectionBanner} />
            </div>
            <BreadcrumbType breadcrumbArray={['Home', 'Services', 'Site Selection']} />
            <div id="content" className="col-md-offset-1 col-md-7 col-sm-12">
                <h1 className="text-green">we can help you with site selection for your new store</h1><p>Considering opening a new convenience store? We offer a hands-on assistance to help you find the best possible location. Many of SEI Fuels business consultants have decades of c-store experience. We can help you understand the business potential of each possible location, and we will help you find ways to reach that potential after your store opens.</p>
                <p>When you are looking at locations, your SEI Fuels business consultant is available to assist you. They can help you estimate the cost of getting a site up and running; as well as help you understand the strengths and weaknesses of your potential location.</p>
                <p>We are here to help you choose, which of our fuel brands would be the most effective in your area. We are unbiased about fuel brands, which is why we offer so many. You can choose the brand that is best for your location.</p>
                <p>We can help maximize the incentives available from the national brands. We will help you seek the best possible deal from the oil company of the brand you choose. We will help you get image money as well as rebates available from the national brands.</p>
                <p>You can choose a traditional supply relationship, or you can operate under a consignment or commission agreement, where we own the fuel and we pay you a fee for selling the fuel at your store.</p>
                <p><a href="/find-a-consultant">Talk to the business consultant in your area today</a>&nbsp;about how SEI Fuels can help you get started.</p>
            </div>
            <div id="sidebar" className="col-sm-12 col-md-3">
                <div>
                    <div className="testimonial-block">
                        <div className="quote">
                            <p>I have been with SEI fuels for some time. All my business dealings with SEI have been very positive. They go beyond their job of being my fuel distributor. I would highly recommend them.</p>
                        </div>
                        <span className="divider"></span>
                        <p className="attribute">
                            <strong>Bob Herrman</strong><br />
                            <em>Austin, TX</em>
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SiteSelection;
