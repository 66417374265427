import React, { useState } from 'react';
import BreadcrumbType from '../../Common/Breadcrumb';
import { Carousel, Col, Form, Stack } from 'react-bootstrap';

/** LosCabosMexico*/
import LosCabosMexicoImage1 from '../../images/galleries/IMG_5114_1410411984_3210.jpg';
import LosCabosMexicoImage2 from '../../images/galleries/IMG_3260_1410411984_9570.jpg';
import LosCabosMexicoImage3 from '../../images/galleries/IMG_3600_1410411984_6303.jpg';
import LosCabosMexicoImage4 from '../../images/galleries/IMG_2125_1410411984_4914.jpeg';
import LosCabosMexicoImage5 from '../../images/galleries/IMG_3606_1410411984_9823.jpg';
import LosCabosMexicoImage6 from '../../images/galleries/IMG_6409_1410411984_9423.jpg';
import LosCabosMexicoImage7 from '../../images/galleries/IMG_0697_1410411984_4000.JPG';
import LosCabosMexicoImage8 from '../../images/galleries/IMG_0695_1410411984_3824.JPG';
import LosCabosMexicoImage9 from '../../images/galleries/IMG_0670_1410411984_4173.jpg';
import LosCabosMexicoImage10 from '../../images/galleries/IMG_0296_1410411984_8788.JPG';
import LosCabosMexicoImage11 from '../../images/galleries/IMG_0697_1410411984_3420.JPG';
import LosCabosMexicoImage12 from '../../images/galleries/SEI_Fuels_Los_Cabos_2019_016_1410411984_8829.JPG';
import LosCabosMexicoImage13 from '../../images/galleries/Image_79_1410411984_4918.png';
import LosCabosMexicoImage14 from '../../images/galleries/SEI_Fuels_Los_Cabos_2019_023_1410411984_3499.JPG';
import LosCabosMexicoImage15 from '../../images/galleries/SEI_Fuels_Los_Cabos_2019_046_1410411984_1553.JPG';
import LosCabosMexicoImage16 from '../../images/galleries/SEI_Fuels_Los_Cabos_2019_037_1410411984_5093.JPG';
import LosCabosMexicoImage17 from '../../images/galleries/SEI_Fuels_Los_Cabos_2019_034_1410411984_8690.JPG';
import LosCabosMexicoImage18 from '../../images/galleries/SEI_Fuels_Los_Cabos_2019_050_1410411984_6212.JPG';
import LosCabosMexicoImage19 from '../../images/galleries/SEI_Fuels_Los_Cabos_2019_054_1410411984_4290.JPG';
import LosCabosMexicoImage20 from '../../images/galleries/SEI_Fuels_Los_Cabos_2019_079_1410411984_5649.JPG';
import LosCabosMexicoImage21 from '../../images/galleries/IMG_6217_1410411984_5429.jpg';
import LosCabosMexicoImage22 from '../../images/galleries/SEI_Fuels_Los_Cabos_2019_091_1410411984_8472.JPG';
import LosCabosMexicoImage23 from '../../images/galleries/SEI_Fuels_Los_Cabos_2019_096_1410411984_4447.JPG';
import LosCabosMexicoImage24 from '../../images/galleries/SEI_Fuels_Los_Cabos_2019_092_1410411984_1845.JPG';
import LosCabosMexicoImage25 from '../../images/galleries/SEI_Fuels_Los_Cabos_2019_093_1410411984_8249.JPG';
import LosCabosMexicoImage26 from '../../images/galleries/SEI_Fuels_Los_Cabos_2019_097_1410411984_3262.JPG';
import LosCabosMexicoImage27 from '../../images/galleries/SEI_Fuels_Los_Cabos_2019_100_1410411984_8791.JPG';
import LosCabosMexicoImage28 from '../../images/galleries/SEI_Fuels_Los_Cabos_2019_109_1410411984_9353.JPG';
import LosCabosMexicoImage29 from '../../images/galleries/SEI_Fuels_Los_Cabos_2019_110_1410411984_6458.JPG';
import LosCabosMexicoImage30 from '../../images/galleries/SEI_Fuels_Los_Cabos_2019_111_1410411984_3837.JPG';
import LosCabosMexicoImage31 from '../../images/galleries/SEI_Fuels_Los_Cabos_2019_113_1410411984_8153.JPG';
import LosCabosMexicoImage32 from '../../images/galleries/SEI_Fuels_Los_Cabos_2019_114_1410411984_2322.JPG';
import LosCabosMexicoImage33 from '../../images/galleries/SEI_Fuels_Los_Cabos_2019_118_1410411984_6011.JPG';
import LosCabosMexicoImage34 from '../../images/galleries/IMG_6409_1410411984_6078.jpg';
import LosCabosMexicoImage35 from '../../images/galleries/IMG_6417_1410411984_2814.jpg';
import LosCabosMexicoImage36 from '../../images/galleries/IMG_0885__002__1410411984_2069.jpg';
import LosCabosMexicoImage37 from '../../images/galleries/IMG_0892__002__1410411984_8638.jpg';
import LosCabosMexicoImage38 from '../../images/galleries/DSC_0632_1410411984_7725.jpg';
import LosCabosMexicoImage39 from '../../images/galleries/SEI_Fuels_Los_Cabos_2019_087_1410411984_5322.JPG';
import LosCabosMexicoImage40 from '../../images/galleries/IMG_0739_1410411984_5603.JPG';


const CustomerRecognization = () => {
    const chunkSize = 5;

    const [activeGallery, setActiveGallery] = useState(loadLosCabosMexicoData());

    const importVancouverCanadaImages = require.context('../../images/galleries/VancouverCanada', false, /\.(jpg|jpeg|png|JPG|JPEG|PNG)$/);
    const vancouverCanadaImages = importVancouverCanadaImages.keys().map((imagePath) => importVancouverCanadaImages(imagePath));

    const importMauiHawaiiImages = require.context('../../images/galleries/MauiHawaii', false, /\.(jpg|jpeg|png|JPG|JPEG|PNG)$/);
    const mauiHawaiiImages = importMauiHawaiiImages.keys().map((imagePath) => importMauiHawaiiImages(imagePath));


    const importPuertaVallartaImages = require.context('../../images/galleries/PuertaVallarta', false, /\.(jpg|jpeg|png|JPG|JPEG|PNG)$/);
    const puertaVallartaImages = importPuertaVallartaImages.keys().map((imagePath) => importPuertaVallartaImages(imagePath));

    const importBermudaCruiseIamages = require.context('../../images/galleries/BermudaCruise', false, /\.(jpg|jpeg|png|JPG|JPEG|PNG)$/);
    const bermudaCruiseImages = importBermudaCruiseIamages.keys().map((imagePath) => importBermudaCruiseIamages(imagePath));

    const importPragueCzechRepublicIamages = require.context('../../images/galleries/PragueCzechRepublic', false, /\.(jpg|jpeg|png|JPG|JPEG|PNG)$/);
    const pragueCzechRepublicImages = importPragueCzechRepublicIamages.keys().map((imagePath) => importPragueCzechRepublicIamages(imagePath));

    const importPacificCoastTourCanadaandUS2013Iamages = require.context('../../images/galleries/PacificCoastTourCanadaandUS2013', false, /\.(jpg|jpeg|png|JPG|JPEG|PNG)$/);
    const pacificCoastTourCanadaandUS2013Images = importPacificCoastTourCanadaandUS2013Iamages.keys().map((imagePath) => importPacificCoastTourCanadaandUS2013Iamages(imagePath));

    const importTETCOGulfCruiseCostaRica2012Iamages = require.context('../../images/galleries/TETCOGulfCruiseCostaRica2012', false, /\.(jpg|jpeg|png|JPG|JPEG|PNG)$/);
    const tETCOGulfCruiseCostaRica2012Images = importTETCOGulfCruiseCostaRica2012Iamages.keys().map((imagePath) => importTETCOGulfCruiseCostaRica2012Iamages(imagePath));

    const onChangeEvent = (event) => {
        event.preventDefault();
        const { value } = event.target;
        if (Number(value) === 2) {
            setActiveGallery([...vancouverCanadaImages]);
        } else if (Number(value) === 3) {
            setActiveGallery([...mauiHawaiiImages]);
        } else if (Number(value) === 4) {
            setActiveGallery([...puertaVallartaImages]);
        } else if (Number(value) === 5) {
            setActiveGallery([...bermudaCruiseImages]);
        } else if (Number(value) === 6) {
            setActiveGallery([...pragueCzechRepublicImages]);
        } else if (Number(value) === 7) {
            setActiveGallery([...pacificCoastTourCanadaandUS2013Images]);
        } else if (Number(value) === 8) {
            setActiveGallery([...tETCOGulfCruiseCostaRica2012Images]);
        } else {
            setActiveGallery(loadLosCabosMexicoData());
        }
    }

    const resultSubArrays = activeGallery.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / chunkSize)
        if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = []
        }
        resultArray[chunkIndex].push(item)

        return resultArray
    }, [])

    return (
        <>
            <BreadcrumbType breadcrumbArray={['Home', 'Company Info', 'Customer Recognition']} />
            <div id="content" className="col-md-offset-1 col-md-7 col-sm-12">
                <h1 className="text-green">here's how we recognize top dealers</h1><p>We want you to feel like you are part of the team. This is an important part of our success. We believe it is extremely important to recognize superior performance so every year we take a group of our top dealers and their guests on an expense-paid trip.</p>
                <p>Recent trips include Cabo, Vancouver, Maui, Puerta Vallarta, Puerto Rico, Bermuda, Prague, and a wine cruise from Canada down the coast to San Diego. This year’s trip is a 3 night stay for two in Vail, Colorado.</p>
                <p>We recognize dealer success, not just high volume. We take the top performers from each brand. Unbranded stores can also participate in this program. We recognize outstanding performance in satisfying customer needs. Any one of our dealers can earn an invitation to SEIFS Circle of Excellence recognition trip.</p>
                <h2>our recent trips</h2>
                <div>
                    <div className="gallery-listing">
                        {/* eslint-disable-next-line */}
                        <form role="form">
                            <div className='form-group'>
                                <Form.Select className="form-control hasCustomSelect" onChange={onChangeEvent} bsPrefix='form-select' aria-label="Reason for Contact">
                                    <option value="1">Los Cabos, Mexico</option>
                                    <option value="2">Vancouver, Canada</option>
                                    <option value="3">Maui, Hawaii</option>
                                    <option value="4">Puerta Vallarta</option>
                                    <option value="5">Bermuda Cruise</option>
                                    <option value="6">Prague, Czech Republic –&nbsp;2014</option>
                                    <option value="7">Pacific Coast Tour, Canada and U.S. –&nbsp;2013</option>
                                    <option value="8">TETCO Gulf Cruise, Costa Rica –&nbsp;2012 (and earlier cruises)</option>
                                </Form.Select>
                            </div>
                        </form>
                    </div>
                </div>

                <div id="load_content">
                    <div id="section_content">
                        <Carousel data-bs-theme="dark">
                            {activeGallery.map(items => (
                                <Carousel.Item>
                                    <img className="d-block w-100" src={items} alt="Customer activity" />
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    </div>
                </div>
                <br></br>
                <div id="slider_thumbs" className="carousel slide" data-ride="carousel" data-interval="false">
                    <div className="carousel-inner">
                        <div className='item active'>
                            <Carousel data-bs-theme="dark">

                                {resultSubArrays.map(subArr => (
                                    <Carousel.Item>
                                        <Stack direction="horizontal" className="h-100 justify-content-center align-items-center" gap={3}>
                                            {subArr.map(item => (
                                                <Col xs={6} md={4}>
                                                    <img className="d-block w-100" src={item} alt="Customer activity" />
                                                </Col>
                                            ))}
                                        </Stack>
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        </div>
                    </div>
                </div>
            </div>
            <div id="sidebar" className="col-sm-12 col-md-3">
                <div>
                    <div className="testimonial-block">
                        <div className="quote">
                            <p>What a wonderful trip!!! Karen and I just wanted to express our appreciation for the terrific time we had last week. SEI did a great job of putting together a memorable program. We are looking forward to Puerto Rico.</p>
                        </div>
                        <span className="divider"></span>
                        <p className="attribute"><strong>Dan and Karen Boschert</strong><br />
                            <em>Dallas, TX</em>
                        </p>
                    </div>
                </div>
                <div>
                    <p><small>
                        <strong>THIS IS NOT A GAME OF CHANCE; VOID WHERE PROHIBITED</strong>.&nbsp; Limited to participating dealers as of 1/1/2022 with SEIFS supply agreement through 12/31/2023 or later.&nbsp; Starts 1/1/2022; ends 12/31/2022.&nbsp; Must remain under same ownership, have good credit standing, &amp; meet all brand requirements. PRIZE: 3-night stay for 2 in Vail, Colorado 6/4/2023 - 6/7/23 (ARV $8,000/prize); 59 prizes will be awarded. Sponsor: SEI Fuel Services, Inc., Wholesale Operations, 3200 Hackberry, Irving, TX 75063.</small>
                    </p>
                </div>
            </div>
        </>
    )

    function loadLosCabosMexicoData() {
        return [
            LosCabosMexicoImage1,
            LosCabosMexicoImage2,
            LosCabosMexicoImage3,
            LosCabosMexicoImage4,
            LosCabosMexicoImage5,
            LosCabosMexicoImage6,
            LosCabosMexicoImage7,
            LosCabosMexicoImage8,
            LosCabosMexicoImage9,
            LosCabosMexicoImage10,
            LosCabosMexicoImage11,
            LosCabosMexicoImage12,
            LosCabosMexicoImage13,
            LosCabosMexicoImage14,
            LosCabosMexicoImage15,
            LosCabosMexicoImage16,
            LosCabosMexicoImage17,
            LosCabosMexicoImage18,
            LosCabosMexicoImage19,
            LosCabosMexicoImage20,
            LosCabosMexicoImage21,
            LosCabosMexicoImage22,
            LosCabosMexicoImage23,
            LosCabosMexicoImage24,
            LosCabosMexicoImage25,
            LosCabosMexicoImage26,
            LosCabosMexicoImage27,
            LosCabosMexicoImage28,
            LosCabosMexicoImage29,
            LosCabosMexicoImage30,
            LosCabosMexicoImage31,
            LosCabosMexicoImage32,
            LosCabosMexicoImage33,
            LosCabosMexicoImage34,
            LosCabosMexicoImage35,
            LosCabosMexicoImage36,
            LosCabosMexicoImage37,
            LosCabosMexicoImage38,
            LosCabosMexicoImage39,
            LosCabosMexicoImage40
        ];
    }
}

export default CustomerRecognization;
