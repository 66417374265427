import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import {
    Link,
} from "react-router-dom";

function Headers() {
    return (
        <Navbar className="col-md-offset-1 col-md-10 navbar-default header-content" role="navigation" collapseOnSelect expand="lg" >
            <Navbar.Brand className="navbar-brand" as={Link} to="/index"></Navbar.Brand>
            <Navbar.Toggle className='navbar-header' data-target="#site_nav" />
            <Navbar.Collapse id="site-nav" className='flex-column navbar-collapse pull-right'>
                <Nav className='navbar-right access '>
                    <p className='site-links hidden-md' >
                        <Nav.Link className='btn-xs-only btn-orange-xs' as={Link} to="/find-a-consultant">Find a Consultant</Nav.Link>&nbsp;&nbsp;&nbsp;&nbsp;<br className="visible-xs-inline"></br>
                        <Nav.Link className='btn-xs-only btn-orange-xs' as={Link} to="/contact-us">Contact Us</Nav.Link>&nbsp;&nbsp;&nbsp;&nbsp;<br className="visible-xs-inline"></br>
                        <Nav.Link className='btn-xs-only btn-orange-xs' as={Link} to="/contact-us">Dealer Portal</Nav.Link>
                    </p>
                    <Nav.Link className='hidden-xs' as={Link} to="/find-a-consultant" >Find a Consultant</Nav.Link><br className="hidden-xs visible-xs-inline"></br>
                    <Nav.Link className='hidden-xs' as={Link} to="/contact-us">Contact Us</Nav.Link><br className="hidden-xs visible-xs-inline"></br>
                    <Nav.Link className='hidden-xs' href="https://www.seifuels.net/account/">Dealer Portal</Nav.Link>
                </Nav>
                <Nav className='nav navbar navbar-right nav-primary'>
                    <NavDropdown title="SERVICES" className="header-nav dropdown" id="navbarScrollingDropdown">
                        <NavDropdown.Item className='header-nav' as={Link} to="/fuel-distribution">FUEL DISTRIBUTION</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item className='header-nav' as={Link} to="/site-selection">
                            SITE SELECTION
                        </NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item className='header-nav' as={Link} to="/7-eleven-franchising">
                            7-ELEVEN FRANCHISING
                        </NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item className='header-nav' as={Link} to="/value-added-services">
                            VALUE-ADDED SERVICES
                        </NavDropdown.Item>
                    </NavDropdown>
                    <Nav.Link className='header-nav' as={Link} to="/our-fuel-brands">FUEL BRANDS</Nav.Link>
                    <Nav.Link className='header-nav' as={Link} to="/career-opportunities">CAREERS</Nav.Link>
                    <NavDropdown title="COMPANY INFO" className="header-nav dropdown" id="navbarScrollingDropdown">
                        <NavDropdown.Item className='header-nav' as={Link} to="/company-information">ABOUT US</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item className='header-nav' as={Link} to="/testimonials">
                            TESTIMONIALS
                        </NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item className='header-nav' as={Link} to="/customer-recognition">
                            CUSTOMER RECOGNITION
                        </NavDropdown.Item>
                    </NavDropdown>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}

export default Headers;
