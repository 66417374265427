import React from 'react'
import { Image } from 'react-bootstrap'
import FuelFranchisingBanner from '../../images/SEI_Fuels_franchising_3_9970.png';
import BreadcrumbType from '../../Common/Breadcrumb';

const Franchising = () => {
    return (
        <>
            <div id='billboard'>
                <Image src={FuelFranchisingBanner} />
            </div>
            <BreadcrumbType breadcrumbArray={['Home', 'Services', '7-Eleven Franchising']} />
            <div id="content" className="col-md-offset-1 col-md-7 col-sm-12">
                <h1 className="text-green">simplified franchises –&nbsp;the 7‑Eleven way</h1>
                <p>As a subsidiary of 7‑Eleven, Inc. we can offer 7-Eleven's Brand Conversion Program ('BCP').&nbsp; BCP is a great option for you to convert your existing store to a 7-Eleven.&nbsp; You can benefit from the same brand strength, merchandising and ongoing support, as the traditional franchise model.&nbsp; Or you may also consider opening a new 7‑ELEVEN franchise.</p>
                <p>The 7‑Eleven franchise department:</p>
                <ul>
                    <li>provides customer service in every aspect of your business</li>
                    <li>invests in your success</li>
                    <li>provides industry-leading franchise support</li>
                    <li>focuses on world-className service to individual stores</li>
                    <li>will help you get the right mix of products on the shelves.</li>
                </ul>
                <p>To learn more about the 7‑Eleven franchise system of training and support, business consulting, advertising and marketing, and product strategy, <a href="http://franchise.7-eleven.com/franchise/home" target="_blank" rel="noopener noreferrer">contact 7‑Eleven's franchising staff today</a>.
                </p>
            </div>
            <div id="sidebar" className="col-sm-12 col-md-3"><p></p>
                <div className="testimonial-block">
                    <div className="quote">
                        <p>In my 15+ years of gas station business – I think I have found a genuine, honest, responsible, dependable jobber in the SEI team. They are prompt and have offered extended help whenever needed. I was even surprised to get a response even they are on vacation! It is amazing.</p>
                    </div>
                    <span className="divider"></span>
                    <p className="attribute"><strong>Jawed Panjwani</strong><br />
                        <em>Austin and Georgetown, TX</em>
                    </p>
                </div>
                <p></p>
            </div>
        </>
    )
}

export default Franchising;
